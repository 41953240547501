import React, { useState } from "react";
import furnitureImage from '../../FrontEnd/img/furniture-design.jpg'


function Chat() {
  return (
    <div>
        <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div className="listing-list">
            <div className="row">
                <div className="col-lg-12 mb-space-remove">
                    <h4>Coming Soon....!</h4>
                    {/* <a href="#chat" title="Send Enquiry" id="enquiry-in">
                        <div className="strip map_view px-4 py-3" style={{ marginBottom: "15px" }}>
                            <div className="enquiry_date">
                                02-08-2023
                            </div>
                            <div className="row align-items-center py-3">
                                <div className="col-md-2 col-4">
                                    <div className="chat_img_sec">
                                        <img src={furnitureImage} alt="image" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-8">
                                    <div className="chat_heading">John Smith</div>
                                    <div className="chat_text">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
                                        voluptate magnam assumenda repudiandae!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a> */}

					
                </div>
            </div>
        </div>
</div>
    </div>
  )
}

export default Chat;