import React from "react";
import rightarrow from '../../../FrontEnd/img/rightarrow3.svg';
import './Legaladvisory.css'

function Pestcontrol(){
    return(
        <>
        <div className="container margin_80_55">
            <div className="main_title_2">
                <h2>Popular Categories</h2>
            </div>
        </div>
        <div className="row justify-content-center categories-list border-box" >
            <div className="col-lg-3 col-6">
                <div className="item">
                    <span className="icon" style={{ width: 'fit-content' }}>
<img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc">Ant Pest Control</a>
                        

                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-6">
                <div className="item">
                    <span className="icon" style={{ width: 'fit-content' }}>
<img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Bird Pest Control</a>
                        

                    </div>
                </div>

            </div>

             <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Cockroach Pest Control</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Fly Pest Control</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Lizard Pest Control</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Mosquito Pest Control</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Rat Pest Control</a>
                        

                    </div>
                </div>

            </div>

           < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Rodent Pest Control</a>
                        

                    </div>
                </div>

            </div>

                       < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Spider Pest Control</a>
                        

                    </div>
                </div>

            </div>

                       < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Termite Pest Control</a>
                        

                    </div>
                </div>

            </div>

                       < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Wasp & Bee Pest Control</a>
                        

                    </div>
                </div>

            </div>

                       < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Wood Borer Pest Control</a>
                        

                    </div>
                </div>

            </div>

            
        </div>
        </>

    )
}
export default Pestcontrol;