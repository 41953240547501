import React from "react";
import rightarrow from '../../../FrontEnd/img/rightarrow3.svg';
import './Legaladvisory.css'

function Automation(){
    return(
        <>
        <div className="container margin_80_55">
            <div className="main_title_2">
                <h2>Popular Categories</h2>
            </div>
        </div>
        <div className="row justify-content-center categories-list border-box" >
            <div className="col-lg-3 col-6">
                <div className="item">
                    <span className="icon" style={{ width: 'fit-content' }}>
<img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc">Video Door Phone</a>
                        

                    </div>
                </div>

            </div>
            <div className="col-lg-3 col-6">
                <div className="item">
                    <span className="icon" style={{ width: 'fit-content' }}>
<img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Video Door Camera</a>
                        

                    </div>
                </div>

            </div>

             <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Electronic Door Lock</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >CCTV Camera</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >IP Camera</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Fire Alarm</a>
                        

                    </div>
                </div>

            </div>

            <div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Home Automation</a>
                        

                    </div>
                </div>

            </div>

           < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Intrusion alarm</a>
                        

                    </div>
                </div>

            </div>

            < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Video Door Pone (VDP)</a>
                        

                    </div>
                </div>

            </div>

            < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Digital Video Recorder (DVR)</a>
                        

                    </div>
                </div>

            </div>

            < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Biometric Attendance Access Control</a>
                        

                    </div>
                </div>

            </div>

            < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Epabx System</a>
                        

                    </div>
                </div>

            </div>

            < div className="col-lg-3 col-6">
                <div className="item">
                   <span className="icon" style={{ width: 'fit-content' }}>
                      <img src={rightarrow} alt="rightarrow" style={{ width: '30px', height: '30px' }} />
                    </span>
                    <div className="detail">
                        <a href="/ListingResults/Advocates-Lawyers-Service/tc" >Intercom System</a>
                        

                    </div>
                </div>

            </div>

            
        </div>
        </>

    )
}
export default Automation;